import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';

import {
  HomeComponent,
  LoginComponent,
  MainComponent,
  SurveyComponent,
  TemoignageComponent,
  QuestionnaireAccueilComponent,
  ResultPageComponent,
  FichesActionsComponent,
  FichesActionsDetailComponent,
  RemerciementsComponent,
  QuestionnairePdfComponent
} from '../../pages/pages';

import { AuthGuard } from '../../guards/auth-guard/auth.guard';

const routes: Routes = [
  {
    path: 'home',
    component: HomeComponent,
    children:
      [
        {
          path: '', component: MainComponent
        },
      ]
  },
  {
    path: 'questionnaire',
    children:
      [
        {
          path: '', component: QuestionnaireAccueilComponent
        },
        { path: 'questions', component: SurveyComponent },
        {
          path: 'remerciement',
          component: RemerciementsComponent,
        },
        /*{
          path: 'result',
          component: ResultPageComponent,
        },*/
        {
          path: 'fiches-actions/:id',
          component: FichesActionsComponent,
        },
        {
          path: 'fiches-actions-detail/:id',
          component: FichesActionsDetailComponent,
        },
        {
          path: 'pdf/:code/:version/:nom',
          component: QuestionnairePdfComponent,
        },
      ]
  },

  {
    path: 'resultat',
    component: ResultPageComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'temoignage',
    component: TemoignageComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'login',
    redirectTo: 'home/login',
    pathMatch: 'full'
  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forRoot(routes)
  ],
  providers: [
    AuthGuard
  ],
  declarations: [],
  exports: [RouterModule]
})

export class RoutingModule {
}
