import { Injectable } from '@angular/core';

/**
 * A simple settings/config class for storing key/value pairs with persistence.
 */
@Injectable()
export class SessionService {
  //déclaration et initialisation des variables
  private SETTINGS_KEY: string = '_settings';
  private CREDENTIAL_KEY = '_credentials';
  settings: any;
  credentials: { token: string; userId: string; } = null;
  _defaults: any;
  _readyPromise: Promise<any>;
  storage: Storage;

  constructor() {
    //initialisation des variables
    this._defaults = {};
    this.storage = window.localStorage;
    this.loadCredentials();
    this.load();
  }

  //pour récupèrer la Lettres de créance
  loadCredentials() {
    let value = this.storage.getItem(this.CREDENTIAL_KEY);

    if (value)
      this.credentials = JSON.parse(value);
    else {
      this.storage.setItem(this.CREDENTIAL_KEY, JSON.stringify({
        userId: null,
        token: null
      }));
    }
  }

  //pour ajouter la Lettres de créance
  setCredentials(value) {
    this.credentials = value;
    return this.storage.setItem(this.CREDENTIAL_KEY, JSON.stringify(this.credentials));
  }

  //pour ajouter la Lettres de créance
  putCredential(key, value) {
    this.credentials[key] = value;
    return this.storage.setItem(this.CREDENTIAL_KEY, JSON.stringify(this.credentials));
  }

  //pour la recuperation
  load() {
    let value = this.storage.getItem(this.SETTINGS_KEY);

    if (value) {
      this.settings = JSON.parse(value);
      return this._mergeDefaults(this._defaults);
    }
    else {
      this.settings = this.setAll(this._defaults);
    }
  }

  //pour la merge
  _mergeDefaults(defaults: any) {
    for (let k in defaults) {
      if (!(k in this.settings)) {
        this.settings[k] = defaults[k];
      }
    }

    return this.setAll(this.settings);
  }

  //pour merger
  merge(settings: any) {
    for (let k in settings) {
      this.settings[k] = settings[k];
    }

    return this.save();
  }

  //ajouter une valeur
  setValue(key: string, value: any) {
    this.settings[key] = value;
    return this.storage.setItem(this.SETTINGS_KEY, JSON.stringify(this.settings));
  }

  //ajouter toutes
  setAll(value: any) {
    this.storage.setItem(this.SETTINGS_KEY, JSON.stringify(value));
    return value;
  }

  //récupèrer une valeur
  getValue(key: string) {
    let value = this.storage.getItem(this.SETTINGS_KEY);

    if (value) {
      let settings = JSON.parse(value);
      return settings[key];
    } else
      return null;
  }

  //la sauvgarder 
  save() {
    return this.setAll(this.settings);
  }

  //avoir les paramètres
  get allSettings() {
    return this.settings;
  }
}
