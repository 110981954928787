import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';
import { SessionService } from '../session/session.service';
import { User } from '../../models/user';
import 'rxjs/add/operator/share';

@Injectable()

export class UserService {
  //declaration et initialisation des variables
  isLogged: boolean = false;
  connectedUser = null;
  _user: User = null;
  token = null;
  redirectUrl: string = null;

  constructor(private api: ApiService, private sessions: SessionService) {
  }

  //methode pour le login 
  login(credentials: { email: string; password: string }) {
    let seq = this.api.post('public/login', credentials).share().map(r => r.json());
    seq
      .subscribe(res => {
        if (res.token) {
          res.user = res.user;
          this._loggedIn(res);
        }
      }, err => console.log('Error', err));

    return seq;
  }

  //pour sauvgarder un provider
  registerProvider(data) {
    let seq = this.api.post('public/registerProvider', data).share().map(r => r.json());

    seq
      .subscribe(res => {
      }, err => console.log('Error', err));

    return seq;
  }

  //pour sauvgarder un client
  registerClient(data) {
    let seq = this.api.post('public/registerClient', data).share().map(r => r.json());

    seq
      .subscribe(res => {
      }, err => console.log('Error', err));

    return seq;
  }

  //pour la deconnexion
  logout() {
    this.sessions.setCredentials({ user: undefined, token: undefined });
  }

  //pour l'inscription
  _loggedIn(resp) {
    this._user = resp.user;
    this.isLogged = true;
    this.token = resp.token;
    this._user = new User(resp.user._id, resp.user.email, resp.user.fullname, resp.user.type);
    this.sessions.putCredential('user', this._user);
    this.sessions.putCredential('token', this.token);
    this.connectedUser = this._user;
  }

  /**
   * this will get the current user based on the token
   * an error will be thrown when the token is invalid
   *
   * @returns {Observable}
   */
  me() {
    let seq = this.api.get('Users/me').share().map(res => res.json());

    seq
      .subscribe(user => {
        this.isLogged = true;
        this._user = new User(user.id, user.email, user.fullname, user.type);
        this.connectedUser = this._user;

        //update the user data in local
        this.sessions.putCredential('user', this._user);
      }, err => {
        this.isLogged = false;
        console.log('Error', err);
      });

    return seq;
  }
}
