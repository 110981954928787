import { Component, OnInit, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { SurveyService } from '../../../services/survey/survey.service';
import { saveAs } from 'file-saver';
import { FileUploader, FileLikeObject } from 'ng2-file-upload';
import { environment } from '../../../../environments/environment';
import { promise } from 'protractor';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthService } from '../../../services/Authentification/auth.service';

@Component({
  selector: 'app-questionnaire-accueil',
  templateUrl: './questionnaire-accueil.component.html',
  styleUrls: ['./questionnaire-accueil.component.scss']
})

export class QuestionnaireAccueilComponent implements OnInit {
  //déclaration des variables
  public qv = 0;
  public static chekedListResponseID: any;
  public questionnaire_id;
  public user_id;
  selectedFile: File = null;

  constructor(private router: Router, private surveyService: SurveyService, private toaster: ToastrService, private spinner: NgxSpinnerService, private auth: AuthService) {
    this.qv = environment.questionnaire_id;
    this.questionnaire_id = this.auth.DSIEnCryptNumber(this.qv, environment.cryptage_one, environment.cryptage_two, environment.cryptage_three, environment.cryptage_four, environment.cryptage_five, environment.cryptage_six, environment.cryptage_seven);
    
  }

  ngOnInit() {
  }

  //pour aller aux questions
  navigateToQuestionnaire() {
    sessionStorage.removeItem('result');
    this.router.navigate(['questionnaire/questions']);
  }

  // save pdf questionnaire chez l'utilisateur 
  savePdf(nameFile: String) {
    this.surveyService.generatePDF().subscribe((r) => {
      var file = new Blob([r.blob()], { type: 'application/pdf' });
      saveAs(file, nameFile);
    })
  }

  //pour l'ouverture de la fenêtre 
  openFileDialogue(event) {
    var inputDialogueFile = document.getElementById('file');
    inputDialogueFile.click();

  }

  //pour lire la base 64
  protected readeBase64(file): Promise<any> {
    var reader = new FileReader();

    var future = new Promise((resolve, reject) => {
      reader.addEventListener('load', function () {
        resolve(reader.result);
      }, false);

      reader.readAsDataURL(file);
    });

    return future;
  }

  //pour uploader une file
  public uploadFile(event) {
    this.selectedFile = <File>event.target.files[0];
    let file = this.readeBase64(this.selectedFile);
    this.spinner.show();

       // si l'extention n'est pas un PDF
       if(this.selectedFile.name.search("[^.]\.pdf$") <= 0 ){
        this.toaster.warning('Seul les PDF sont acceptés');
        this.spinner.hide();
        return false;
    }

    // si le fichier depaasse 10MB 
    else if((this.selectedFile.size/1024/1024) >= 10){
        this.toaster.warning('Taille fichier ne doir pas depasse 10MB');
        this.spinner.hide();
        return false;
    }

    // copier d'objet component
    let objThis = this;
    file.then(function (dataFile64) {
      //On crée un user et on upload le fichier
      objThis.addUserFo(objThis, dataFile64);
      objThis.spinner.show();
    });


    file.catch(function () {
      // on ferme spinner dans le cas d'error
      objThis.spinner.hide();
    });

    // clean input file
    event.target.value = "";

    
  }

  //Méthode pour ajouter un user et upload fichier
  public addUserFo(objThis: any, dataFile64: any) {
    let user_id = '';

    let params = {
      'questionnaire_id': this.questionnaire_id,
      'user_id': user_id
    };

    this.surveyService.addUserFo(params).subscribe((r: any) => {
      let resp = r.json();
      sessionStorage.removeItem('user_id');
      sessionStorage.setItem('user_id', resp.user_id);

      this.user_id = resp.user_id;

      objThis.surveyService.uploadFile(dataFile64, objThis.qv).subscribe((r) => {
        if (r.status.toString() == '200') {
          let resultatPdf = JSON.parse(r.text());
          let versionPdf = resultatPdf.resulat_pdf.code;

          if (versionPdf == 1) {
            sessionStorage.setItem('note_final_user', resultatPdf.resulat_pdf.note_final_user);

            // on passe resulat et status du PDF au page de resutat
            QuestionnaireAccueilComponent.chekedListResponseID = resultatPdf.resulat_pdf.fields_Cheked_id;
            sessionStorage.setItem('result','true');
            objThis.router.navigate(['/resultat'], QuestionnaireAccueilComponent.chekedListResponseID);
          }

          else if (versionPdf == 2) {
            objThis.toaster.info('Version PDF incorrecte !');
            objThis.spinner.hide();
            return false;
          }

          else if (versionPdf == 3) {
            objThis.toaster.info('Veuillez cochez au moins une reponse pour chaque question !');
            objThis.spinner.hide();
            return false;
          }
        }
      });
    }, err => {
    });
  }
}
