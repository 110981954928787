import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FicheActionService } from '../../../../../services/service';
import { IHeaderItems, HeaderItems } from '../../../../../commun/dynamic-header.interface';

@Component({
  selector: 'app-fiches-actions-detail',
  templateUrl: './fiches-actions-detail.component.html',
  styleUrls: ['./fiches-actions-detail.component.scss']
})

export class FichesActionsDetailComponent implements OnInit {
  //déclaration et initialisation des variale
  public fiche_id: number = 0;
  public ficheActionDetailArr: any[] = [];
  public items: IHeaderItems;
  public description: string = '';
  public benefices: string = '';
  public impacts: string = '';
  public title: string = '';

  constructor(private router: Router, private serviceFicheActionDetail: FicheActionService) {
  }

  ngOnInit() {
    //récupèrer les detailes de fiche action 
    this.getFicheActionDetail();
  }

  //récupèrer les details d'un fiche action
  getFicheActionDetail() {
    // on récupère l'id de de la fiche action  pour faire l'appel
    this.fiche_id = Number(this.router.url.split('/')[3]);

    //appelle de la API pour avoir toutes le détail de la fiche
    this.serviceFicheActionDetail.getFicheActionDetail(this.fiche_id).subscribe(resp => {
      this.description = resp.data[0].description;
      this.benefices = resp.data[0].benefices;
      this.impacts = resp.data[0].impacts;
      console.log(this.impacts);
      //initialisation du header
      this.title = resp.data[0].name;
      // this.titreaxe = (this.router.url.split('/')[3].toString());

    });
  }

  //aller aux resultat 
  goToResult() {
    this.router.navigate(['/questionnaire/fiches-actions/', this.fiche_id]);
  }
}
