//interface pour header dynamique 
export interface IHeaderItems {
  titreLeft?: string;//titre qui ce trouve à guauche
  titreCenter?: string;//titre qui se trouve au centre
  underTitreCenter?: string;//le sous titre qui se trouve au centre
  nbrAxe?: number;//le nombre d'axe
  step?: number;//l'avancement de la reponse
  progess?: boolean;//true => afficher le bloc d'avencement
  progessBar?: boolean;//true => afficher le progress bar
  is_survey?: boolean;
}

export class HeaderItems implements IHeaderItems {
  public titreLeft?: string;
  public titreCenter?: string;
  public underTitreCenter?: string;
  public nbrAxe?: number;
  public step?: number;
  public progess?: boolean;
  public progessBar?: boolean;
  public is_survey?: boolean;

  constructor() {
    this.titreLeft = ""
    this.titreCenter = ""
    this.underTitreCenter = "";
    this.nbrAxe = 1;
    this.step = 1;
    this.progess = false;
    this.progessBar = false;
    this.is_survey =  false;
  }
}
