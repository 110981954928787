import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpModule, Http } from '@angular/http';
import { FormsModule } from '@angular/forms';
import { RoutingModule } from './modules/routing/routing.module';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { AppComponent } from './app.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ChartsModule } from 'ng2-charts';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { FileUploadModule } from 'ng2-file-upload';

import {
  LoginComponent,
  HomeComponent,
  MainComponent,
  HeaderComponent,
  FooterComponent,
  SurveyComponent,
  TemoignageComponent,
  QuestionnaireAccueilComponent,
  ResultPageComponent,
  FichesActionsComponent,
  FichesActionsDetailComponent,
  RemerciementsComponent,
  DynamicHeaderComponent,
  QuestionnairePdfComponent
} from './pages/pages';

import {
  ApiService,
  SessionService,
  UserService,
  SurveyService,
  AxeService,
  FicheActionService,
  CommunService,
  TemoignagesService
} from './services/service';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HomeComponent,
    MainComponent,
    HeaderComponent,
    FooterComponent,
    SurveyComponent,
    TemoignageComponent,
    QuestionnaireAccueilComponent,
    ResultPageComponent,
    FichesActionsComponent,
    FichesActionsDetailComponent,
    RemerciementsComponent,
    DynamicHeaderComponent,
    QuestionnairePdfComponent
  ],

  imports: [
    BrowserModule,
    RoutingModule,
    HttpModule,
    FormsModule,
    CommonModule,
    NgxPaginationModule,
    BrowserAnimationsModule, 
    ToastrModule.forRoot(),
    NgxSpinnerModule,
    ChartsModule,
    NgbModule,
    FileUploadModule
  ],

  providers: [
    ApiService,
    SessionService,
    UserService,
    SurveyService,
    AxeService,
    FicheActionService,
    CommunService,
    TemoignagesService
  ],
  bootstrap: [AppComponent]
})

export class AppModule {
}
