import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../../services/user/user.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})

export class LoginComponent implements OnInit {
  //déclaration et initialisation des variables
  @ViewChild('close') closeModal: ElementRef;
  credentials = { email: '', password: '' };
  userData = {
    email: '',
    fullname: '',
    password: '',
    confirmPassword: ''
  };

  constructor(private toaster: ToastrService, private userService: UserService, private router: Router) {
  }

  ngOnInit() {
  }

  //pour enregistrer un client
  register() {
    this.userService.registerClient(this.userData).subscribe(r => {
      this.toaster.success('Enregistrement reussi');
      this.closeModal.nativeElement.click();
    }, err => {
      this.toaster.error(err.message);
    });
  }

  //pour le login
  login() {
    this.userService.login(this.credentials).subscribe(r => {
      if (!r.token) {
        this.toaster.error(`Ce compte n'est pas encore vérifié par l'administrateur, vous receverez un mail une fois fait`);
        return;
      }

      const route = this.userService.redirectUrl || '/';
      this.toaster.info('Bienvenue ' + r.user.email);
      this.router.navigate([route]);
    }, err => {
      this.toaster.error(err.message);
    });
  }
}
