import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { SurveyService, AxeService } from '../../services/service';
import { AuthService } from '../../services/Authentification/auth.service';
import { QuestionnaireAccueilComponent } from '../survey/questionnaire-accueil/questionnaire-accueil.component';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { trigger, transition, style, animate, useAnimation, group } from '@angular/animations';
import { zoomIn, fadeInDown, pulse, fadeIn, fadeOut } from 'ng-animate';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css'],
  animations: [
    trigger('bounceInLeft', [
      transition(':enter', [
        style({ transform: 'translateX(-100%)' }),
        animate(550)
      ]),
      transition(':leave', [
        group([
          animate('0.2s ease', style({
            transform: 'translate(150px,25px)'
          })),
          animate('0.5s 0.2s ease', style({
            opacity: 0
          }))
        ])
      ])
    ]),
    trigger('bounceInRight', [
      transition(':enter', [
        style({ transform: 'translateX(100%)' }),
        animate(550)
      ]),
      transition(':leave', [
        group([
          animate('0.2s ease', style({
            transform: 'translate(150px,25px)'
          })),
          animate('0.5s 0.2s ease', style({
            opacity: 0
          }))
        ])
      ])
    ]),

    trigger('fadeInDown', [transition('* => *', useAnimation(fadeInDown, {
      // Set the duration to 5seconds and delay to 2seconds
      params: { timing: 2, delay: 0 }
    }))]),

    trigger('fadeIn', [transition('* => *', useAnimation(fadeIn, {
      // Set the duration to 5seconds and delay to 2seconds
      params: { timing: 2, delay: 0 }
    }))]),
    
    trigger('zoomIn', [transition('* => *', useAnimation(zoomIn, {
      // Set the duration to 5seconds and delay to 2seconds
      params: { timing: 2, delay: 0 }
    }))]),
    trigger('pulse', [transition('* => *', useAnimation(pulse, {
      // Set the duration to 5seconds and delay to 2seconds
      params: { timing: 5, delay: 1 }
    }))]),
  ],
})

export class MainComponent implements OnInit {
  //declaration et initialisation des variables
  fadeInDown: any;
  bounceInLeft: any;
  bounceInRight: any;
  zoomIn: any;
  pulse: any;
  public URL = '';
  public qv = 0;
  public user_id;
  public static chekedListResponseID: any;
  selectedFile: File = null;
  public questionnaire_id;

  constructor(private router: Router, private surveyService: SurveyService, private auth: AuthService,
    private toaster: ToastrService,
    private spinner: NgxSpinnerService) {
    this.URL = environment.API_BASE_URL;
    this.qv = environment.questionnaire_id;
     this.questionnaire_id = this.auth.DSIEnCryptNumber(this.qv, environment.cryptage_one, environment.cryptage_two, environment.cryptage_three, environment.cryptage_four, environment.cryptage_five, environment.cryptage_six, environment.cryptage_seven);
   
  }

  //pour lire la base 64
  protected readeBase64(file): Promise<any> {
    var reader = new FileReader();
    var future = new Promise((resolve, reject) => {
      reader.addEventListener('load', function () {
        resolve(reader.result);
      }, false);

      reader.readAsDataURL(file);
    });
    return future;
  }

  //pour l'upload de PDF
//pour uploader une file
public uploadFile(event) {
  this.selectedFile = <File>event.target.files[0];
  let file = this.readeBase64(this.selectedFile);
  this.spinner.show();

     // si l'extention n'est pas un PDF
     if(this.selectedFile.name.search("[^.]\.pdf$") <= 0 ){
      this.toaster.warning('Seul les PDF sont acceptés');
      this.spinner.hide();
      return false;
  }

  // si le fichier depaasse 10MB 
  else if((this.selectedFile.size/1024/1024) >= 10){
      this.toaster.warning('Taille fichier ne doir pas depasse 10MB');
      this.spinner.hide();
      return false;
  }

  // copier d'objet component
  let objThis = this;
  file.then(function (dataFile64) {
    //On crée un user et on upload le fichier
    objThis.addUserFo(objThis, dataFile64);
    objThis.spinner.show();
  });


  file.catch(function () {
    // on ferme spinner dans le cas d'error
    objThis.spinner.hide();
  });

  // clean input file
  event.target.value = "";

  
}


  /** 
  public uploadFile(event) {
    
    this.selectedFile = <File>event.target.files[0];

    // si l'extention n'est pas un PDF
    if(this.selectedFile.name.search("[^.]\.pdf$") <= 0 ){
        this.toaster.warning('Seul les PDF sont acceptés');
        this.spinner.hide();
        return false;
    }

    // si le fichier depaasse 10MB 
    else if((this.selectedFile.size/1024/1024) >= 10){
        this.toaster.warning('Taille fichier ne doir pas depasse 10MB');
        this.spinner.hide();
        return false;
    }
    
    let file = this.readeBase64(this.selectedFile);
    let objThis = this;
   
    //objThis.spinner.show();
    file.then(function (dataFile64) {
      
      objThis.surveyService.uploadFile(dataFile64, objThis.qv).subscribe((r) => {
       
        
        if (r.status.toString() == '200') {
          let resultatPdf = JSON.parse(r.text());
          let versionPdf = resultatPdf.resulat_pdf.code;

          if (versionPdf == 1) {
            sessionStorage.setItem('note_final_user', resultatPdf.resulat_pdf.note_final_user);
            // on passe resulat et status du PDF au page de resutat
            QuestionnaireAccueilComponent.chekedListResponseID = resultatPdf.resulat_pdf.fields_Cheked_id;
            objThis.router.navigate(['/resultat'], QuestionnaireAccueilComponent.chekedListResponseID);
          }

          else if (versionPdf == 2) {
            objThis.spinner.hide();
            objThis.toaster.warning('Version PDF incorrect !');
            return false;
          }

          else if (versionPdf == 3) {
            objThis.spinner.hide();
            objThis.toaster.warning('Veuillez cochez au moins une reponse pour chaque question !');
            return false;
          }

        }
        objThis.spinner.hide();
      });

      // clean input file
      event.target.value = "";
    })
  }
*/
  public addUserFo(objThis: any, dataFile64: any) {
    let user_id = '';

    let params = {
      'questionnaire_id': this.questionnaire_id,
      'user_id': user_id
    };

    this.surveyService.addUserFo(params).subscribe((r: any) => {
      let resp = r.json();
      sessionStorage.removeItem('user_id');
      sessionStorage.setItem('user_id', resp.user_id);

      this.user_id = resp.user_id;

      objThis.surveyService.uploadFile(dataFile64, objThis.qv).subscribe((r) => {
        if (r.status.toString() == '200') {
          let resultatPdf = JSON.parse(r.text());
          let versionPdf = resultatPdf.resulat_pdf.code;

          if (versionPdf == 1) {
            sessionStorage.setItem('note_final_user', resultatPdf.resulat_pdf.note_final_user);

            // on passe resulat et status du PDF au page de resutat
            QuestionnaireAccueilComponent.chekedListResponseID = resultatPdf.resulat_pdf.fields_Cheked_id;
            sessionStorage.setItem('result','true');
            objThis.router.navigate(['/resultat'], QuestionnaireAccueilComponent.chekedListResponseID);
          }

          else if (versionPdf == 2) {
            objThis.toaster.info('Version PDF incorrecte !');
            objThis.spinner.hide();
            return false;
          }

          else if (versionPdf == 3) {
            objThis.toaster.info('Veuillez cochez au moins une reponse pour chaque question !');
            objThis.spinner.hide();
            return false;
          }
        }
      });
    }, err => {
    });
  }

  //ouvrire la fenetre pour selectionner le pdf
  openFileDialogue(event) {
    var inputDialogueFile = document.getElementById('file');
    inputDialogueFile.click();
  }

  ngOnInit() {
  }

  // pour aller au questionnaire
  navigateToQuestionnaireAccueil() {
    this.router.navigateByUrl('questionnaire');
  }


}

