import { Component, OnInit } from '@angular/core';
import { ActivatedRoute,Router } from "@angular/router";
import { environment } from '../../../../environments/environment';
import { SurveyService, AxeService } from '../../../services/service';
import { AuthService } from '../../../services/Authentification/auth.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { version } from 'punycode';

@Component({
  selector: 'app-questionnaire-pdf',
  templateUrl: './questionnaire-pdf.component.html',
  styleUrls: ['./questionnaire-pdf.component.scss']
})

export class QuestionnairePdfComponent implements OnInit {
  //déclaration et initialisation des variables
  public code: string = "";
  public version: string = "";
  public nom: string = "";
  public questionnaire_id;
  public user_id;
  public dateResponsePdf: object;

  constructor(private route: ActivatedRoute, 
    private surveyService: SurveyService,
    private auth: AuthService,
    private spiner: NgxSpinnerService,
    private router: Router) { 

    //récupèrer les paramétre de la route
    this.route.paramMap.subscribe(params => {
      this.code = params.get("code");
      this.version = params.get("version");
      this.nom = params.get("nom")
    });
    
    this.questionnaire_id = environment.questionnaire_id;

    //On crypte le questionnaire id
    this.questionnaire_id = this.auth.DSIEnCryptNumber(this.questionnaire_id, environment.cryptage_one, environment.cryptage_two, environment.cryptage_three, environment.cryptage_four, environment.cryptage_five, environment.cryptage_six, environment.cryptage_seven);    
  }

  ngOnInit() {
    //afficher la progress bar
    this.spiner.show();

    //creer un user fo aprés 5 second
    setTimeout(()=>{
      this.createUserFO();
    }, 5000);
  }

  //Méthode pour creer un user fo
  createUserFO(){
    let user_id = '';

    let params = {
      'questionnaire_id': this.questionnaire_id,
      'user_id': user_id
    };
    
    this.surveyService.addUserFo(params).subscribe((r: any) => {
      let resp = r.json();
      sessionStorage.removeItem('user_id');
      sessionStorage.setItem('user_id', resp.user_id);

      this.user_id = resp.user_id;
      this.savePDFResult();
    }, err => {
    });
  }

  //sauvgarder la resultat au format PDF
  savePDFResult(){  
      this.surveyService.savePDFResult(this.code, this.nom, this.version).subscribe((r : any) => {
        let resp = r.json();
        let note_final_user = resp.note_final_user;
        let user_id = resp.user_id;

        sessionStorage.setItem('note_final_user', note_final_user);
        sessionStorage.setItem('user_id', user_id);

        this.spiner.hide();
        this.router.navigate(['/resultat']);
    }, err => {
    });
  }
}
