import {HomeComponent} from './home/home.component';
import {LoginComponent} from './login/login.component';
import {MainComponent} from './main/main.component';
import {HeaderComponent} from './header/header.component';
import {FooterComponent} from './footer/footer.component';
import {SurveyComponent} from './survey/survey.component';
import {TemoignageComponent} from './temoignage/temoignage.component';
import {QuestionnaireAccueilComponent} from './survey/questionnaire-accueil/questionnaire-accueil.component';
import {ResultPageComponent} from './survey/result-page/result-page.component';
import {FichesActionsComponent} from './survey/result-page/fiches-actions/fiches-actions.component';
import {FichesActionsDetailComponent} from './survey/result-page/fiches-actions/fiches-actions-detail/fiches-actions-detail.component';
import {RemerciementsComponent} from './survey/result-page/remerciements/remerciements.component';
import {DynamicHeaderComponent} from '../template/dynamic-header/dynamic-header.component';
import { QuestionnairePdfComponent } from './survey/questionnaire-pdf/questionnaire-pdf.component';

export {
    HomeComponent,
    LoginComponent,
    MainComponent,
    HeaderComponent,
    FooterComponent,
    SurveyComponent,
    TemoignageComponent,
    QuestionnaireAccueilComponent,
    ResultPageComponent,
    FichesActionsComponent,
    FichesActionsDetailComponent,
    RemerciementsComponent,
    DynamicHeaderComponent,
    QuestionnairePdfComponent
};