import { Component, OnInit } from '@angular/core';
import { IHeaderItems, HeaderItems } from '../../commun/dynamic-header.interface';
import { TemoignagesService } from '../../services/service';
import { AuthService } from '../../services/Authentification/auth.service';

@Component({
  selector: 'app-temoignage',
  templateUrl: './temoignage.component.html',
  styleUrls: ['./temoignage.component.scss']
})

export class TemoignageComponent implements OnInit {
  //déclaration et initialisation des variables
  public items: IHeaderItems;
  public company_id: string = "1";
  public titre: string = "";
  public body: string = "";
  public temoignages: any[] = [];
  public strToken = "";

  constructor(private service: TemoignagesService, private auth: AuthService) {
    this.strToken = this.auth.getToken();
  }

  ngOnInit() {
    this.callApi();
    
    //initialisation header
    this.items = new HeaderItems;
    this.items = {
      titreLeft: "TEMOIGNAGES",
      progess: false,
      progessBar: false
    }
  }

  //apeller l'api
  callApi() {
    //appeller le service pour avoir les temoignages
    this.service.getAllTemoignage().subscribe((r) => {
      this.temoignages = r.json().data;
    });
  }
}
