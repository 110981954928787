import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})

export class AuthService {

  constructor(private api: ApiService) {
    
  }

  // pour obtenir un question selon id take et skip
  loginUser() {
    let email = environment.email;
    let password = environment.password;

    const obj = {
      email: email,
      password: password
    };

    return this.api.post('login', obj);
  }

  //récupère un token
  getToken() {
    let strToken = '';
    strToken = sessionStorage.getItem('token');

    if (strToken != null && strToken != undefined && strToken != 'undefined' && strToken != '') {
      return strToken;
    }

    return "";
  }

  //pour crypter un numero
  DSIEnCryptNumber(myNbr: number, one: string, two: string, three: number, four: number, five: number, six: string, seven: string) {
    return one + '' + two + '' + (myNbr * three / four + five) + '' + six + '' + seven;
  }

  //pour décrypter un numero
  DSIDecryptNumber(str: string, one: string, two: string, three: number, four: number, five: number, six: string, seven: string) {
    str = str.split(one)[1];
    str = str.split(two)[1];
    str = str.split(six)[0];
    str = str.split(seven)[0];
    str = Number(str) - five + "";
    str = Number(str) / (three / four) + "";
    str = Math.round(Number(str)) + "";
    return str;
  }
}
