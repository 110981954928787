import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { IHeaderItems, HeaderItems } from '../../commun/dynamic-header.interface';
import { trigger, transition, style, animate, useAnimation } from '@angular/animations';
import { zoomIn, fadeInDown } from 'ng-animate';

@Component({
  selector: 'app-dynamic-header',
  templateUrl: './dynamic-header.component.html',
  styleUrls: ['./dynamic-header.component.scss'],
  animations: [
    trigger('zoomIn', [transition('* => *', useAnimation(zoomIn, {
      // Set the duration to 5seconds and delay to 2seconds
      params: { timing: 0.5, delay: 0 }
    }))]),
    trigger('fadeInDown', [transition('* => *', useAnimation(fadeInDown, {
      // Set the duration to 5seconds and delay to 2seconds
      params: { timing: 1, delay: 0 }
    }))]),

  ]
})

export class DynamicHeaderComponent implements OnInit {
  //declaration et initialisation des variables
  fadeInDown: any;
  zoomIn: any;
  @Input('items') items: IHeaderItems;
  public percentage: string;

  constructor() {
    //initialisation des variables
    this.percentage = '';
    this.items = new HeaderItems;
  }

  ngOnInit() {
  }

  //quand on changer une variables
  ngOnChanges(changes: SimpleChanges) {
    //pour calculer le pourcentage
    try {
      this.percentage = (((this.items.step - 1) * 100) / this.items.nbrAxe) + '';
      this.percentage = Math.round(Number(this.percentage)) + '';
    } catch (e) {
    }
  }
}
