import { Component, OnInit } from '@angular/core';
import { SurveyService, AxeService } from '../../services/service';
import { Router } from '@angular/router';
import { AuthService } from '../../services/Authentification/auth.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../../environments/environment';
import { IHeaderItems, HeaderItems } from '../../commun/dynamic-header.interface';
import { trigger, transition, useAnimation } from '@angular/animations';
import { zoomIn } from 'ng-animate';

@Component({
  selector: 'app-survey',
  templateUrl: './survey.component.html',
  styleUrls: ['./survey.component.scss'],
  animations: [
    trigger('zoomIn', [transition('* => *', useAnimation(zoomIn, {
      // Set the duration to 5seconds and delay to 2seconds
      params: { timing: 2, delay: 0 }
    }))])]
})

export class SurveyComponent implements OnInit {
  //déclaration et initialisation des variables 
  surveys: any[] = [];
  public questionnaire_id;
  public take: number = 4;
  public skip: number = 0;
  public dataResult: any[] = [];
  public pageView: number = 1;
  public itemAfficher = 2;
  public survey: any[] = [];
  public isLast = false;
  public addUserExecuted: boolean = true;
  public isAllQstChecked = false;
  public buttonSuiv: boolean = true;
  public buttonPrec: boolean = false;
  public buttonSave: boolean = false;
  public axe_id = 2;
  public axeArray: any[] = [];
  public currentAxe: string = "1";
  public items: IHeaderItems;
  public user_id;
  public index: number = 1;
  public tempIndex = 0;
  public check_profil_question: any[] = [];
  public next_axe: any=false;

  constructor(private surveyService: SurveyService, private router: Router,
    private auth: AuthService, private serviceAxe: AxeService, private spinner: NgxSpinnerService,
    private toaster: ToastrService) {

    //pour redireger au questionnaire si il a taper l'url des questions directement
    if (this.auth.getToken() == "") {
      this.router.navigate(['/questionnaire']);
    }

    this.questionnaire_id = environment.questionnaire_id;

    //On crypte le questionnaire id
    this.questionnaire_id = this.auth.DSIEnCryptNumber(this.questionnaire_id, environment.cryptage_one, environment.cryptage_two, environment.cryptage_three, environment.cryptage_four, environment.cryptage_five, environment.cryptage_six, environment.cryptage_seven);

    sessionStorage.removeItem("note_final_user");

    if (sessionStorage.getItem('user_id') != null && sessionStorage.getItem('user_id') != undefined && sessionStorage.getItem('user_id') != 'undefined' && sessionStorage.getItem('user_id') != '') {
      this.user_id = sessionStorage.getItem('user_id');
    }
  }

  ngOnInit() {
    //j'ai commenté this.addUserFo(); car j'ai deja ajouté un userFo dès l'ouverture dans la header component
    this.addUserFo();

    //on recupere le quetionnaire du premier axe
    window.scroll(0, 0);

    //on garde en session l'axe courant
    sessionStorage.setItem('currentAxe', '0');

    //on recupere l'axe gardé en session
    this.currentAxe = sessionStorage.getItem('currentAxe');

    //la condition pour afficher le boutton suivant
    if (this.axeArray.length == Number(this.currentAxe) + 1) {
      this.buttonSuiv = false;
    }
    else {
      this.buttonSuiv = true;
    }

    //la condition pour afficher le boutton précédant
    if (this.currentAxe == '0') {
      this.buttonPrec = false;
    }
    else {
      this.buttonPrec = true;
    }
  }

  // appel de l'api pour recuperer les questionnaires
  loadSurvey(idAxe: string) {
    //afficher le progress bar
    this.spinner.show();

    this.surveyService.getaxeQuestionWizard(this.questionnaire_id, idAxe).subscribe(r => {
      if (r.status != undefined && r.status != '') {
        window.location.reload();
      }
      else {
        //console.log(r.data)
        this.surveys = r.data;
        this.tempIndex = this.surveys.length;
        //enlever le progress bar
        this.spinner.hide();
        this.next_axe=true;
        // on crée un user fo après chargement du questionnaire une seule fois
        if (this.addUserExecuted = true) {
          this.addUserExecuted = false;
        }
      }
    }, err => {
      this.spinner.hide();
    });
  }

  //ajouter les réponses de l'utilsateur en base
  addResponseUser() {
    this.surveyService.addResponse(this.dataResult).subscribe(r => {
    }, err => {
    });

    this.toaster.success('Ajouté avec succès', 'Alerte');

    // redirection après validation des réponses vers la page de fin
    sessionStorage.setItem('result', 'true');
    this.router.navigate(['/resultat']);
    this.updateUserFo();
  }

  //ajouter  l'utilsateur user Fo  en base
  addUserFo() {
    let user_id = '';

    let params = {
      'questionnaire_id': this.questionnaire_id,
      'user_id': user_id
    };

    this.surveyService.addUserFo(params).subscribe((r: any) => {
      let resp = r.json();
      sessionStorage.removeItem('user_id');
      sessionStorage.setItem('user_id', resp.user_id);

      this.user_id = resp.user_id;
      this.getAllAxesForQuestionnaire();
    }, err => {
    });
  }

  //récupèrer toutes les axes
  getAllAxesForQuestionnaire() {
    //appelle de la API pour avoir tous les axes
    this.serviceAxe.getAllAxes(this.questionnaire_id, this.user_id).subscribe(resp => {
      this.axeArray = resp[0].data_axes;

      //on garde les axes en session
      sessionStorage.setItem('axes', JSON.stringify(this.axeArray));
      this.loadSurvey(this.axeArray[0].id);

      //initialiser le header dynamique
      this.initilizeHeader();
    });
  }

  // pour mettre à jour un user fo
  updateUserFo() {
    this.surveyService.updateUserFo([1]).subscribe(r => {
    }, err => {
    });
  }

  //pour le bouton precedant
  getPreviousSurvey() {
    //on recupere l'axe courant gardé en session
    this.currentAxe = sessionStorage.getItem('currentAxe');
    this.next_axe=true;
    //on fait appelle pour recuperer les questions de l'axe précedant
    this.currentAxe = (Number(this.currentAxe) - 1).toString();

    this.loadSurvey(this.axeArray[this.currentAxe].id);
    console.log(this.axeArray[this.currentAxe].id);

    this.surveyService.getaxeQuestionWizard(this.questionnaire_id, this.axeArray[this.currentAxe].id).subscribe(r => {

      this.index = this.index - r.data.length;
    })

    window.scroll(0, 0);

    //on garde en session l'axe courant
    sessionStorage.setItem('currentAxe', this.currentAxe);

    //on recupere les axe gardés en session au format JSON
    this.axeArray = JSON.parse(sessionStorage.getItem('axes'));

    //les conditions pour afficher le boutton precedant
    if (this.currentAxe == '0') {
      this.buttonPrec = false;
    } else {
      this.buttonPrec = true;
    }

    //les conditions pour afficher le boutton suivant
    if (this.axeArray.length == Number(this.currentAxe) + 1) {
      this.buttonSuiv = false;
    } else {
      this.buttonSuiv = true;
    }

    //la condition pour afficher le boutton save
    if (this.axeArray.length == Number(this.currentAxe)) {
      this.buttonSave = true;
    }
    else {
      this.buttonSave = false;
    }
    //initialiser le header dynamique
    this.initilizeHeader();
  }

  //pour le bouton suivant
  getNextSurveyOrSave(saveOrSuivant: string) {
    //console.log('here');
    //les variables locaux utilisé dans cette function
    let responseIndex: any[] = [];
    let idQuestions: any[] = [];
    this.currentAxe = sessionStorage.getItem('currentAxe');
    
    this.surveyService.getaxeQuestionWizard(this.questionnaire_id, this.axeArray[this.currentAxe].id).subscribe(r => {
      this.tempIndex = r.data.length;
      //avoir les id de toutes les  questions
      for (let q = 0; q < r.data.length; q++) {
        idQuestions.indexOf(q) === -1 ? idQuestions.push(q) : false;
      }

      //Vérifier les indexs des reponses cochées
      for (let i = 0; i < this.dataResult.length; i++) {
        if (idQuestions.includes(this.getQuestionIndex(this.dataResult[i]))) {
          responseIndex.indexOf(this.getQuestionIndex(this.dataResult[i])) === -1 ? responseIndex.push(this.getQuestionIndex(this.dataResult[i])) : false;
        }

      }

      // si toutes les questions d'un axe sont cochées
      if (responseIndex.length == this.surveys.length && this.next_axe!=false) {
        if (saveOrSuivant == 'suiv') {
          this.next_axe=false;
          //on recupere l'axe courant gardé en session
          this.currentAxe = sessionStorage.getItem('currentAxe');

          //on fait appelle pour recuperer les questions de l'axe suivant
          this.currentAxe = (Number(this.currentAxe) + 1).toString();

          this.loadSurvey(this.axeArray[this.currentAxe].id);

          window.scroll(0, 0);

          //on garde en session l'axe courant
          sessionStorage.setItem('currentAxe', this.currentAxe);

          //on recupere les axe gardés en session au format JSON
          this.axeArray = JSON.parse(sessionStorage.getItem('axes'));

          //la condition pour afficher le boutton suivant
          if (this.axeArray.length == Number(this.currentAxe) + 1) {
            this.buttonSuiv = false;
          } else {
            this.buttonSuiv = true;
          }

          //la condition pour afficher le boutton save
          if (this.axeArray.length == Number(this.currentAxe) + 1) {
            this.buttonSave = true;
          } else {
            this.buttonSave = false;
          }

          //la condition pour afficher le boutton precedant
          if (this.currentAxe == '0') {
            this.buttonPrec = false;
          } else {
            this.buttonPrec = true;
          }
        }
        else if ('save') {
          this.surveyService.addResponse(this.dataResult).subscribe(r => {
            let resp = r.json();

            if (resp.note_final_user != null && resp.note_final_user != undefined) {
              sessionStorage.setItem('note_final_user', resp.note_final_user);

              this.updateUserFo();
              sessionStorage.setItem('result', 'true');

              // redirection après validation des réponses vers la page de fin
              this.router.navigate(['/resultat']);
            }
          }, err => {
          });
        };

        this.index = this.index + this.tempIndex;
      } else {
        this.toaster.info("Répondez à toutes les questions pour passer à la page suivante");
      }
      //initialiser le header dynamique
      this.initilizeHeader();
    });

  }

  // on vérifie si une réponse a été slectinné ou déselectionné
  onResponseSelectionChange(e, reponse_id: string, categorieName: string, questionName: any): void {
    let that = this;
    this.next_axe=true;


    if (categorieName == 'Profil') {
      if (this.check_profil_question.indexOf(questionName.id) == -1) {

        this.check_profil_question.push(questionName.id);
        this.dataResult.push(reponse_id);
      }else {
        if (this.dataResult.indexOf(reponse_id) == -1) {
          Object.keys(questionName).map(function (key) {
            Object.keys(questionName[key]).map(function (key1) {
              if (questionName[key][key1]['id'] != undefined || questionName[key][key1]['id'] != null) {

                let lucky_number = that.dataResult.filter(function (id) {
                  return id == questionName[key][key1]['id'];
                });

                that.dataResult.splice(that.dataResult.indexOf(lucky_number), 1);
                that.dataResult.push(reponse_id);
              }
            });
          });
        }
      }

    }
    else {
      //  console.log('jere');
      if (this.dataResult.indexOf(reponse_id) == -1) {
        this.dataResult.push(reponse_id);
      }
      else {
        this.dataResult.splice(this.dataResult.indexOf(reponse_id), 1);
      }
    }
  }

  //on check les reponse cochées par l'utulisateur
  check(reponse_id) {
    var ischeked = '';

    for (let j = 0; j < this.dataResult.length; j++) {
      if (this.dataResult[j] == reponse_id) {
        ischeked = 'checked';
      }
    }

    return ischeked;
  }

  checkProfil(reponse_id) {
    var ischeked = '';

    for (let j = 0; j < this.dataResult.length; j++) {
      if (this.dataResult[j] == reponse_id) {
        ischeked = 'checked';
      }
    }

    return ischeked;
  }

  //on retourne l'index d'une question
  getQuestionIndex(reponse_id: number) {
    var index = -1;

    for (let i = 0; i < this.surveys.length; i++) {
      for (let j = 0; j < this.surveys[i].responses.length; j++) {
        if (this.surveys[i].responses[j].id == reponse_id) {
          index = i;
        }
      }
    }

    return index;
  }

  //initialiser le dinamique header
  initilizeHeader() {
    //initialiser les variables de cette fonction
    let titreCentre = '';
    let progessBar = true;
    this.items = new HeaderItems;

    //condition pour savoir si on est dans la premiere ou la deusième axe
    if (this.currentAxe == '0') {
      titreCentre = 'Mieux vous connaitre ... ';
      progessBar = false;
    } else if (this.currentAxe == '1') {
      titreCentre = 'Commençons par là ... ';
      progessBar = true;
    } else {
      titreCentre = '';
      progessBar = true;
    }

    this.items = {
      titreLeft: this.axeArray[Number(this.currentAxe)].name,
      titreCenter: titreCentre,
      nbrAxe: this.axeArray.length,
      step: Number(this.currentAxe) + 1,
      progess: false,
      progessBar: progessBar,
      is_survey: true

    };
  }
}
