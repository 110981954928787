import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { IHeaderItems, HeaderItems } from '../../../../commun/dynamic-header.interface';
import { trigger, transition, useAnimation } from '@angular/animations';
import { bounceInLeft, bounceInRight, bounceInDown } from 'ng-animate';

@Component({
  selector: 'app-remerciements',
  templateUrl: './remerciements.component.html',
  styleUrls: ['./remerciements.component.scss'],
  animations: [
    trigger('bounceInLeft', [transition('* => *', useAnimation(bounceInLeft, {
      // Set the duration to 5seconds and delay to 2seconds
      params: { timing: 5, delay: 0 }
    }))]),
    trigger('bounceInRight', [transition('* => *', useAnimation(bounceInRight, {
      // Set the duration to 5seconds and delay to 2seconds
      params: { timing: 5, delay: 0 }
    }))]),
    trigger('bounceInDown', [transition('* => *', useAnimation(bounceInDown, {
      // Set the duration to 5seconds and delay to 2seconds
      params: { timing: 5, delay: 0 }
    }))])
  ],
})

export class RemerciementsComponent implements OnInit {
  //déclarations et initialisation des variables
  public items: IHeaderItems;
  bounceInRight: any;
  bounceInLeft: any;
  bounceInDown: any;

  constructor(private router: Router) {
    //on point en haut de la page
    window.scroll(0, 0);

    //initialisation d'interface
    this.items = new HeaderItems;
    this.items = {
      titreLeft: 'A bientôt !',
      progess: false,
      progessBar: false,
    };
  }

  ngOnInit() {
  }

  // pour aller à la page  de resultat
  goToResult() {
    this.router.navigateByUrl('/resultat');
  }
}
