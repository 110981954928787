// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  API_BASE_URL :"http://localhost:8000/api/",
  email : "said@mail.com",
  password : "secret",
  company_token: "123456789",
  questionnaire_id: 1,
  cryptage_one: "*",
  cryptage_two: "k",
  cryptage_three: 2,
  cryptage_four: 2.25,
  cryptage_five: 7000,
  cryptage_six: "k",
  cryptage_seven : "*"
};
