import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';

@Injectable({
  providedIn: 'root'
})

export class TemoignagesService {
  //declaration et initialisation des variables
  public user_id;

  constructor(private api: ApiService) {
    this.user_id = sessionStorage.getItem('user_id');
    console.log(this.user_id);
  }

  // pour obtenir toutes les fiches actions d'un axe
  getAllTemoignage() {
    let obj = {
    }

    let seq = this.api.post("temoignages", obj).share();
    return seq;
  }
}
