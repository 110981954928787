import { Component } from '@angular/core';
import { AuthService } from './services/Authentification/auth.service';
import { trigger, transition, useAnimation } from '@angular/animations';
import { rotateIn } from 'ng-animate';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  animations: [
    trigger('rotateIn', [transition('* => *', useAnimation(rotateIn, {
      // Set the duration to 5seconds and delay to 2seconds
      params: { timing: 3, delay: 0 }
    }))])
  ],
})

export class AppComponent {
  //declarations et initialisation des variables
  rotateIn: any;
  title = 'app';

  constructor(private auth: AuthService,private router: Router) {
    sessionStorage.removeItem('token');
    if(sessionStorage.getItem('result')=='true'){
      sessionStorage.removeItem('result');
      router.navigate(['/']);
    }
    
    
    let userToekn = this.auth.getToken();
   

    //On vérifier le user token de l"utilisateur connecté
    if (userToekn == null || userToekn == '' || userToekn == 'null') {
      //On recupère le token utilisateur
      this.auth.loginUser().subscribe((r: any) => {
        let resp = r.json();
        let token = resp.token;

        if (token != '') {
          sessionStorage.setItem('token', token);
        }
      });
    }
  }

  //pour pointer en haut de la page
  goToTop() {
    window.scroll(0, 0);
  }
}
