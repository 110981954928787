import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';
import { share } from 'rxjs-compat/operator/share';
import { map } from 'rxjs-compat/operator/map';

@Injectable({
  providedIn: 'root'
})

export class FicheActionService {
  constructor(private api: ApiService) {
  }

  // pour obtenir toutes les fiches actions d'un axe
  getAllFicheAxe(questionnaire_id, user_id) {
    let seq = this.api.get('ficheActionAxe/' + questionnaire_id + '/' + user_id).share()
      .map(r => r.json());
    return seq;
  }

  // pour obtenir le détail d'une fiche action
  getFicheActionDetail(id) {
    let seq = this.api.get('ficheActionDetail/' + id).share()
      .map(r => r.json());
    return seq;
  }
}
