import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FicheActionService } from '../../../../services/service';
import { environment } from '../../../../../environments/environment'
import { HeaderItems, IHeaderItems } from '../../../../commun/dynamic-header.interface';
import { AuthService } from '../../../../services/Authentification/auth.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-fiches-actions',
  templateUrl: './fiches-actions.component.html',
  styleUrls: ['./fiches-actions.component.scss']
})

export class FichesActionsComponent implements OnInit {
  //déclarations et initialisation des variables
  public axe_id: number = 0;
  public responseArr: any[] = [];
  public axeName: string;
  public questionnaire_id;
  public user_id;
  public items: IHeaderItems;
  private fragment: string;

  constructor(private router: Router, private serviceFicheAction: FicheActionService, private authService: AuthService, private route: ActivatedRoute) {
    //initialisation des variables
    this.questionnaire_id = environment.questionnaire_id;
    this.items = new HeaderItems;
    this.items = {
      titreLeft: "Opportunités de progrès",
      progess: false,
      progessBar: false
    }
  }

  ngOnInit() {
    //récupèrer les fiche actions
    this.getFicheActions();
    this.route.fragment.subscribe(
      fragment => {
        this.fragment = fragment;
      });
  }

  //récupère les fiches actions
  getFicheActions() {
    // on récupère l'id de l'axe pour faire l'appel
    this.axe_id = Number(this.router.url.split("/")[3]);

    if (sessionStorage.getItem("user_id") != null && sessionStorage.getItem("user_id") != undefined && sessionStorage.getItem("user_id") != 'undefined' && sessionStorage.getItem("user_id") != "") {
      this.user_id = sessionStorage.getItem("user_id");
    }

    //On crypte le questionnaire id
    this.questionnaire_id = this.authService.DSIEnCryptNumber(this.questionnaire_id, environment.cryptage_one, environment.cryptage_two, environment.cryptage_three, environment.cryptage_four, environment.cryptage_five, environment.cryptage_six, environment.cryptage_seven);

    //appelle de la API pour avoir toutes les fiches actions d'un axe
    this.serviceFicheAction.getAllFicheAxe(this.questionnaire_id, this.user_id).subscribe((resp: any) => {
      this.responseArr = resp.data;
      try {
        setTimeout(() => {
          document.getElementById(this.fragment + '').scrollIntoView();
        }, 1);
      }
      catch (e) {
      }
    });
  }

  //return un boolean
  isFicheAction(fiches_actions: any) {
    let result = false;

    if (fiches_actions != null && fiches_actions.length > 0) {
      result = true;
    }

    return result;
  }

  //aller aux resultats
  goToResult() {
    this.router.navigate(['/resultat']);
  }
}
