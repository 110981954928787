import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { UserService } from '../../services/user/user.service';
import { SessionService } from '../../services/session/session.service';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private user: UserService, private router: Router, private settings: SessionService) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (!this.settings.credentials) {
      this.settings.loadCredentials();
    }

    let url: string = state.url;

    if (url.match(/resultat/) && (sessionStorage.getItem('result')!=='true')) {
      this.router.navigate(['/']);
    }
    

    if (this.settings.credentials.token) {
      return new Promise<boolean>((resolve) => {
        this.user.me().subscribe(res => {

          if (url.match(/login/)) {
            resolve(this.check(url, next));
          } else {
            resolve(this.check(url, next));
          }
        }, err => {
          resolve(this.check(url, next));
        });
      });
    } else {
      return Promise.resolve(this.check(url, next));
    }
  }


  //check l'authentification
  check(url: string, next?: ActivatedRouteSnapshot) {
    if (url.match(/login/) && this.user.isLogged) {
      this.router.navigate(["/home"]);
      return true;
    }

    if (next) {
      if (next.data.auth && !this.user.isLogged) {
        this.user.redirectUrl = url;
        this.router.navigate(["/login"]);
        return true;
      }
    }

    return true;
  }
}
