import { Injectable, Input } from '@angular/core';
import { ApiService } from '../api/api.service';
import { share } from 'rxjs-compat/operator/share';
import { map } from 'rxjs-compat/operator/map';
import { saveAs } from 'file-saver';
import { helpers } from 'chart.js';
import { AuthService } from '../Authentification/auth.service';
import { environment } from '../../../environments/environment';

@Injectable({providedIn: 'root'})

export class SurveyService {
  //déclaations et initialisation des variables
  xhr: XMLHttpRequest = new XMLHttpRequest();;
  urlToFilePDF: Object;
  activeMenu : boolean;

  constructor(private api: ApiService, private auth: AuthService) {
    
  }

  // pour obtenir tous les questionnaires
  getAll(filter?) {
    let seq = this.api.get('questionnaires', filter ? { filter: JSON.stringify(filter) } : {}).share().map(r => r.json());
    return seq;
  }

  //pour obtenir un seul questionnaire
  getOne(id) {
    let seq = this.api.get('questionnaires/' + id).share().map(r => r.json());
    return seq;
  }

  // pour obtenir un question selon id take et skip
  getQuestionWizard(questionnaire_id, take, skip) {
    let seq = this.api.get('getQuestionWizard/' + questionnaire_id + '/' + take + '/' + skip).share()
      .map(r => r.json());
    return seq;
  } x

  // pour obtenir toutes les questions d'un axe
  getaxeQuestionWizard(questionnaire_id, axe_id) {
    let seq = this.api.get('getAxeQuestions/' + questionnaire_id + '/' + axe_id).share()
      .map(r => r.json());
    return seq;
  }

  //pour ajouter une reponse de userFo
  addResponse(data: any) {
    let user_id = sessionStorage.getItem('user_id');

    const obj = {
      data: data,
      user_id: user_id
    };

    let seq = this.api.post('addResponses', obj);
    return seq;
  }

  //pour ajouter un user Fo après chargement du questionnaire
  addUserFo(data: any) {
    const obj = {
      data: data
    };

    let seq = this.api.post('addUserFo', obj);
    return seq;
  }

  //pour mettre à jour un user fo
  updateUserFo(id) {
    let seq = this.api.post('updateUserFo', id);
    return seq;
  }

  noteAxe(filter?) {
    return false;
    let seq = this.api.get('noteAxe', filter ? { filter: JSON.stringify(filter) } : {}).share().map(r => r.json());
    return seq;
  }

  // pour ajouter les informations d'un utilisateur
  addUserInfo(email: string, note_finale: number, check1: boolean, check2: boolean, dataImg: string, chekedListResponseID: any) {
    let user_id = '';

    if (sessionStorage.getItem('user_id') != null && sessionStorage.getItem('user_id') != 'null' && sessionStorage.getItem('user_id') != undefined && sessionStorage.getItem('user_id') != 'undefined') {
      user_id = sessionStorage.getItem('user_id');
    }

    const obj = {
      email: email,
      note_finale: note_finale,
      check1: check1,
      check2: check2,
      user_id: user_id,
      imgData: dataImg,
      chekedListResponseID: chekedListResponseID
    };

    let seq = this.api.post('addUserResult', obj);
    return seq;
  }

  // pour ajouter les résultats d'un utilisateur
  addUserResult(data: any) {
    const obj = {
      data: data
    };

    let seq = this.api.post('addUserResult', obj).map(r => r.json);
    return seq;
  }

  //pour generer un PDF
  generatePDF() {
    const obj = {
      questionnaire_id: this.auth.DSIEnCryptNumber(environment.questionnaire_id, environment.cryptage_one, environment.cryptage_two, environment.cryptage_three, environment.cryptage_four, environment.cryptage_five, environment.cryptage_six, environment.cryptage_seven)
    };

    return this.api.postPDF('generate-pdf', obj);
  }

  //pour l'upload de PDF
  uploadFile(filePdfToUplad: any, qv) {
    let user_id = '';
    if (sessionStorage.getItem('user_id') != null && sessionStorage.getItem('user_id') != 'null' && sessionStorage.getItem('user_id') != undefined && sessionStorage.getItem('user_id') != 'undefined') {
      user_id = sessionStorage.getItem('user_id');
    }
    const obj = {
      questionnaire_pdf: filePdfToUplad,
      qv: qv,
      user_id: user_id
    };
    //console.log(obj.questionnaire_pdf);
    return this.api.uploadPostPDF('validePdf', obj);
  }

  //sauvgarder un PDF
  savePDFResult(code, nom, version) {
    let user_id = '';

    if (sessionStorage.getItem('user_id') != null && sessionStorage.getItem('user_id') != 'null' && sessionStorage.getItem('user_id') != undefined && sessionStorage.getItem('user_id') != 'undefined') {
      user_id = sessionStorage.getItem('user_id');
    }

    let obj = {
      code: code,
      nom: nom,
      version: version,
      user_id: user_id
    }

    let seq = this.api.post('getReponseFromPdf', obj);
    return seq;
  }
}
