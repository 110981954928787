import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';

@Injectable({
  providedIn: 'root'
})

export class AxeService {
  constructor(private api: ApiService) {
  }

  // pour obtenir tous les axes
  getAllAxes(questionnaire_id, user_id) {
    let strUser_id = '0';
  
    if (user_id == undefined || user_id == 'undefined') {
      strUser_id = '';
    } else {
      strUser_id = user_id;
    }

    let seq = this.api.get('axes/' + questionnaire_id + '/' + strUser_id).share().map(r => r.json());

    return seq;
  }

  // pour obtenir tous les axes
  getAllAxesResults(questionnaire_id, user_id) {
    let strUser_id = '0';
  
    if (user_id == undefined || user_id == 'undefined') {
      strUser_id = '';
    } else {
      strUser_id = user_id;
    }

    let seq = this.api.get('axesResults/' + questionnaire_id + '/' + strUser_id).share().map(r => r.json());

    return seq;
  }
}
