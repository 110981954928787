import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../../services/service';
import { environment } from '../../../environments/environment';
import { SurveyService } from '../../services/service';
import { AuthService } from '../../services/Authentification/auth.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})

export class HeaderComponent implements OnInit {
  //declaration et initialisation des variables
  protected questionnaire_id: string;
  protected user_id: string;
  protected result:boolean=false;

  constructor(public userService: UserService, private router: Router, private surveyService: SurveyService, private auth: AuthService) {
  }

  ngOnInit() {
  }

  //pour fermer le nav bar quand on click sur un element
  toggleNavBar() {
    let element: HTMLElement = document.getElementsByClassName('navbar-toggler')[0] as HTMLElement;
    if (element.getAttribute('aria-expanded') == 'true') {
      element.click();
    }
  }

  checkSession(){
    
    if(sessionStorage.getItem('result')=='true'){
      this.result=true;
    }else{
      this.result=false;
    }
    return this.result;
  }
  //pour ajouter un user fo
  addUserFo() {
    let user_id = '';
    this.questionnaire_id = this.auth.DSIEnCryptNumber(environment.questionnaire_id, environment.cryptage_one, environment.cryptage_two, environment.cryptage_three, environment.cryptage_four, environment.cryptage_five, environment.cryptage_six, environment.cryptage_seven)

    let params = {
      'questionnaire_id': this.questionnaire_id,
      'user_id': user_id
    };

    this.surveyService.addUserFo(params).subscribe((r: any) => {
      let resp = r.json();
      sessionStorage.removeItem('user_id');
      sessionStorage.setItem('user_id', resp.user_id);
    }, err => {
    });
  }
}
