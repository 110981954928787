import { Component, OnInit } from '@angular/core';
import { SurveyService, AxeService, CommunService } from '../../../services/service';
import { Router } from '@angular/router';
import { ChartDataSets, ChartType } from 'chart.js';
import { Label } from 'ng2-charts';
import { environment } from '../../../../environments/environment';
import { HeaderItems, IHeaderItems } from '../../../commun/dynamic-header.interface';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../../../services/Authentification/auth.service';
import { QuestionnaireAccueilComponent } from '../questionnaire-accueil/questionnaire-accueil.component';
import { bounceInLeft, bounceInRight, pulse, fadeInDown, fadeOut } from 'ng-animate';
import { trigger, transition, useAnimation } from '@angular/animations';

@Component({
  selector: 'app-result-page',
  templateUrl: './result-page.component.html',
  styleUrls: ['./result-page.component.scss'],
  animations: [
    trigger('bounceInLeft', [transition('* => *', useAnimation(bounceInLeft, {
      // Set the duration to 5seconds and delay to 2seconds
      params: { timing: 5, delay: 0.1 }
    }))]),
    trigger('bounceInRight', [transition('* => *', useAnimation(bounceInRight, {
      // Set the duration to 5seconds and delay to 2seconds
      params: { timing: 5, delay: 0.1 }
    }))]),
    trigger('pulse', [transition('* => *', useAnimation(pulse, {
      // Set the duration to 5seconds and delay to 2seconds
      params: { timing: 5, delay: 0.1 }
    }))]),
  ],
})

export class ResultPageComponent implements OnInit {
  //déclarations des variables pour l'animations
  bounceInLeft: any;
  bounceInRight: any;
  pulse: any;
  toastRef;
  // déclaration des variables 
  public noteAxe: any[] = [];
  public markByAxe: any[] = [];
  public noteFinale = 0;
  public arrResulatReadPdf = '';
  public strNoteFinale: string = "0";
  public email: string = "";
  public note_finale: number;
  public checkbox1: boolean;
  public checkbox2: boolean;
  public isOne: boolean;
  public isTwo: boolean;
  public isThree: boolean;
  public isFour: boolean;
  public questionnaire_id;
  public items: IHeaderItems;
  public id_clickakedAxe: number;
  public idChekedResponse: any;
  public radarChartLabels: Label[] = [];
  radarChartData: any[] = [];
  public axeArray: any[] = [];
  public radarChartType: ChartType = 'radar';
  public user_id;
  public doughnutChartOptions: any = {
    backgroundColor: [
      '#ced',
      '#fda',
      '#fdd',
    ],
    responsive: true,
    elements: 
    { 
        point: 
        {
            radius: 7,
            //hitRadius: 5,
            hoverRadius: 9,
            //hoverBorderWidth: 2
        }
    }, 
showAllTooltips: true ,
tooltips: {
  enabled: true,
  callbacks: {
      label: function(tooltipItem, data) {
          return data.datasets[tooltipItem.datasetIndex].label + ' : ' + data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index].toFixed(1);
      }
  }
},
scale: {
  pointLabels: {
    fontSize: 13
  },
  ticks: {
            beginAtZero: true,
            max: 4,
            backdropColor: '#f0f0f0' 
        },
}};

  public chartColors: Array<any> = [
    { // second color
      backgroundColor: 'rgba(70, 178, 124,0.5)',
      borderColor: 'rgba(70, 178, 124,0.5)',
      pointBackgroundColor: 'rgba(70, 178, 124,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(70, 178, 124,1)'
    },
    { // first color
      backgroundColor: 'rgba(70, 44, 134,0.5)',
      borderColor: 'rgba(70, 44, 134,0.2)',
      pointBackgroundColor: 'rgba(70, 44, 134,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(70, 44, 134,1)'
    }
   ];

  public displayRadar = false;



  constructor(private surveyService: SurveyService,
    private router: Router,
    private serviceAxe: AxeService,
    public communService: CommunService,
    public toaster: ToastrService,
    public authService: AuthService) {

      console.log(sessionStorage.getItem('result'));
    //déclaration des variables
    this.items = new HeaderItems;
    this.items = {
      titreLeft: 'RESULTATS',
      titreCenter: 'Félicitations !',
      underTitreCenter: 'vous avez terminé',
      progess: false,
      progessBar: false,
    };

    //this.note_finale = this.noteFinale;
    this.checkbox1 = null;
    this.checkbox2 = null;
    this.questionnaire_id = environment.questionnaire_id;

    //On crypte le questionnaire id
    this.questionnaire_id = this.authService.DSIEnCryptNumber(this.questionnaire_id, environment.cryptage_one, environment.cryptage_two, environment.cryptage_three, environment.cryptage_four, environment.cryptage_five, environment.cryptage_six, environment.cryptage_seven);

    if (sessionStorage.getItem("user_id") != null && sessionStorage.getItem("user_id") != undefined && sessionStorage.getItem("user_id") != 'undefined' && sessionStorage.getItem("user_id") != "") {
      this.user_id = sessionStorage.getItem("user_id");
    }

    //récupère la note d'utulisateur
    try {
      if (sessionStorage.getItem('note_final_user') != null) {
        this.noteFinale = parseFloat(sessionStorage.getItem('note_final_user'));
        this.strNoteFinale = this.noteFinale.toFixed(2);

        //on affiche la note et ca paragraph
        if (Number(this.strNoteFinale) >= 0 && Number(this.strNoteFinale) <= 1.8) {
          this.isOne = true;
        } else if (Number(this.strNoteFinale) > 1.8 && Number(this.strNoteFinale) <= 2.5) {
          this.isTwo = true;
        } else if (Number(this.strNoteFinale) > 2.5 && Number(this.strNoteFinale) <= 3.2) {
          this.isThree = true;
        } else if (Number(this.strNoteFinale) > 3.2 && Number(this.strNoteFinale) <= 4) {
          this.isFour = true;
        }
      }
    } catch (e) {
    }

    //appelle de la API pour avoir tous les axes
    this.serviceAxe.getAllAxesResults(this.questionnaire_id, this.user_id).subscribe(resp => {

      if (resp == "0" || resp == 0) {
        //pour ne pas afficher le radar
        this.displayRadar = false;
      } else {
        //pour afficher le radar
        this.displayRadar = true;

        //pour remplire les infos de radar
        this.radarChartData = [
          { data: resp[0].data_note, label: 'Votre Note', backgroundColor: 'rgba(246, 244, 205, 0.79)', borderColor: 'rgba(246, 244, 205, 0.79)', pointBackgroundColor: 'rgb(246, 244, 205)' },
          { data: resp[0].data_note_moyen, label: 'Note Moyenne', backgroundColor: 'rgba(235, 114, 133, 0.86)', borderColor: 'rgba(235, 114, 133, 0.86)', pointBackgroundColor: 'rgb(235, 114, 133)' }
        ];

        console.log(this.radarChartData);

        this.axeArray = resp[0].data_axes;

        for (let i = 0; i < this.axeArray.length; i++) {
          this.radarChartLabels.push(this.axeArray[i].name);
        }
      }

    });
  }

  ngOnInit() {
    //on point en haut de la page
    window.scroll(0, 0);

    this.noteUserByAxe();
  }

  //radar event
  public chartHovered({ event, active }: { event: MouseEvent, active: {}[] }): void {
  }

  //quand on click sur un axe
  public chartClicked(e: any): void {
    if (e.active.length > 0) {
      const chart = e.active[0]._chart;
      const activePoints = chart.getElementAtEvent(e.event);

      if (activePoints.length > 0) {
        const clickedElementIndex = activePoints[0]._index;
        const label = chart.data.labels[clickedElementIndex];

        // pour obtenir l'element cliqué
        let item = this.axeArray.find(item => item.name === label);

        //on extrait l'id de l'élément
        this.id_clickakedAxe = Number(item.id);

        // on passe l'id de l'élément pour aller à la page fiche action
        this.router.navigate(['questionnaire/fiches-actions', this.id_clickakedAxe], { fragment: this.id_clickakedAxe + '' });

        console.log(this.id_clickakedAxe)
      }
    }
  }

  // pour charger les notes  d'un user fo
  noteUserByAxe() {
  }

  //pour remplir les notes de l'utilisateur à envoyer
  resultUser() {
    alert();
    for (let i = 0; i < this.noteAxe.length; i++) {
      this.markByAxe.push(this.noteAxe[i].axe_avg);
    }

    return this.markByAxe;
  }

  // sauvegarder les infos de l'utilisateur
  send() {
    let canvas: HTMLCanvasElement;
    let dataImg: string = '';
    canvas = document.getElementById('chart') as HTMLCanvasElement;

    if (canvas != null) {
      dataImg = canvas.toDataURL();
    }

    if (this.email != '' && this.checkbox1 != null && this.checkbox2 != null) {
      if (!this.communService.validateEmail(this.email)) {
        this.toaster.info('Veuillez saisir un email au format valide !');
      } else {

        this.surveyService.addUserInfo(this.email, this.noteFinale, this.checkbox1, this.checkbox2, dataImg, QuestionnaireAccueilComponent.chekedListResponseID).subscribe(r => {
          this.router.navigateByUrl('questionnaire/remerciement');
        }, err => {
          //console.log(" erreur of adding user infos")
        });
      }
    } else {
      //this.toaster.warning('Veuillez renseigner tous les champs s’il vous plait');
      this.toaster.info('Veuillez renseigner tous les champs s’il vous plait');
    }
  }

  // sauvegarder les résultats de l'utilisateur
  addUserResult() {
    this.surveyService.addUserResult(this.resultUser()).subscribe(r => {

    }, err => {
      //console.log(" erreur of adding user result");
    });
  }

  //pour le radio button true=> ca m'interesse false=> ca ne m'interesse pas
  onClickRadio1(e, isSelected: boolean) {
    this.checkbox1 = isSelected;
  }

  //pour le radio button true=> oui false=> non
  onClickRadio2(e, isSelected: boolean) {
    this.checkbox2 = isSelected;
  }
}
